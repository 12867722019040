import { Box, Button, Chip, Collapse, Stack } from "@mui/material"
import useCourseActions from "../../../homeNavigation/hooks/useCourseActions"
import useDirectoryItems from "../../../homeNavigation/hooks/useDirectoryItems"
import useDragDropItems from "../../../homeNavigation/hooks/useDragDropItems"
import useWelcomeDisplay from "../../../welcome/hooks/useWelcomeDisplay"
import { Add } from "@mui/icons-material"
import { debounce, map } from "lodash/fp"
import PageLayout from "../../layouts/PageLayout"
import { useMemo, useState } from "react"
import { sortOptions } from "../../../welcome/hooks/useCoursesDisplay"
import { DndContext } from "@dnd-kit/core"
import ItemActionDialogs, {
  CANCEL_UPLOAD,
} from "./components/DirectoryItemActionDialogs"
import ListContainer from "../../itemList/ListContainer"
import ShareCourseDialog from "../../../../components/screens/ShareCourseDialog"
import { useHistory } from "react-router-dom"
import FolderNavBar from "../../../homeNavigation/components/FolderNavBar"
import { Item } from "../../../homeNavigation/types"
import DirectoryItemList from "./components/DirectoryItemList"
import { DialogAction, DialogActionName, UseDirectoryItemsProps } from "./types"
import SortByControl from "../../components/SortByControl"
import SearchBar from "../../components/SearchBar"
import WelcomeDisplay from "../../welcomeDisplay/WelcomeDisplay"

const { TRASH, MOVE_COURSE, CREATE_FOLDER } = DialogActionName

const SEARCH_DEBOUNCE_TIME = 500

/**
 * Page to display Courses and Folders
 * @param props - Component props
 * @param props.location - Location in history when rendering page
 */
const CoursesPage = (props: { location: any }) => {
  const history = useHistory()
  const { location } = props
  const directoryItemProps = useDirectoryItems()
  const {
    refreshItems,
    items,
    handleMoveItems,
    directory,
    checkedItems,
    setCheckedItems,
    handleCreateDirectory,
    handleRenameDirectory,
    handleExitDirectory,
    setQuery,
    currentFolder,
    handleCreateCourseClicked,
    handleSelectSortOption,
    sortBy,
    displaySearchResults,
  }: UseDirectoryItemsProps = directoryItemProps

  const { duplicateCourse } = useCourseActions(refreshItems)

  const { refreshLastUpdatedCourse, ...welcomeProps } = useWelcomeDisplay()

  const { sensors, isDragging, onDragStart, onDragEnd } =
    useDragDropItems(handleMoveItems)

  const onSearchInputChange = debounce(SEARCH_DEBOUNCE_TIME, (query) => {
    setQuery(query)
  })
  const [dialogAction, setDialogAction] = useState<DialogAction | null>(null)

  /**
   * Flag to see if any of the checked items are folders
   */
  const foldersSelected = useMemo(
    () =>
      checkedItems?.some((id) => {
        const item = items.find((item: any) => item.id === id)
        return item && item.item_type === "folder"
      }),
    [checkedItems, items]
  )

  /**
   * Display confirmation dialog for moving course to trash
   * @param ids - List of IDs of courses to trash
   */
  const onTrash = (ids: string[]) => {
    setDialogAction({
      name: TRASH,
      targetItems: items.filter((item) => {
        return ids.includes(item.id)
      }),
    })
  }

  /**
   * Display confirmation dialog for moving courses to a folder
   * @param ids - List of IDs of courses to move
   */
  const onMove = (ids: string[]) => {
    setDialogAction({
      name: MOVE_COURSE,
      targetItems: items.filter((course) => ids.includes(course.id)),
    })
  }

  const showActionDialog = dialogAction && dialogAction?.targetItems
  return (
    <>
      <PageLayout navRail location={location}>
        <Stack width="100%">
          <WelcomeDisplay {...welcomeProps} />
          <ListContainer
            title="Courses"
            searchBar={
              <SearchBar
                displaySearchResults={displaySearchResults}
                onSearchInputChange={onSearchInputChange}
                onClearSearch={() => setQuery("")}
              />
            }
            controls={
              <>
                <Button
                  startIcon={<Add />}
                  variant="contained"
                  color="primary"
                  data-cy="create-course-button"
                  onClick={handleCreateCourseClicked}
                >
                  Add Course
                </Button>
                <Button
                  startIcon={<Add />}
                  disabled={currentFolder != null}
                  variant="outlined"
                  color="primary"
                  onClick={() =>
                    setDialogAction({ name: CREATE_FOLDER, targetItems: [] })
                  }
                >
                  Add Folder
                </Button>
                <Box display="flex" alignItems="center" marginLeft="auto">
                  <SortByControl
                    options={sortOptions}
                    sortBy={sortBy}
                    handleSelectSortOption={handleSelectSortOption}
                  />
                </Box>
              </>
            }
          >
            <Collapse in={checkedItems.length > 0}>
              <Stack
                direction="row"
                paddingX={3}
                gap={1}
                marginBottom={1}
                justifyContent="space-between"
              >
                <Stack direction="row" gap={1} alignItems="center">
                  <Chip
                    clickable
                    disabled={foldersSelected}
                    onClick={() => onMove(checkedItems)}
                    variant="outlined"
                    label={`Move selected`}
                  />

                  <Chip
                    clickable
                    onClick={() => onTrash(checkedItems)}
                    variant="outlined"
                    label={`Trash selected`}
                  />
                </Stack>

                <Stack direction="row">
                  <Button
                    size="small"
                    onClick={() => {
                      setCheckedItems(map("id", items))
                    }}
                  >
                    Select all
                  </Button>
                  <Button size="small" onClick={() => setCheckedItems([])}>
                    Deselect all
                  </Button>
                </Stack>
              </Stack>
            </Collapse>
            <Box paddingLeft={3} paddingRight={3}>
              <FolderNavBar
                displaySearchResults={displaySearchResults}
                folder={currentFolder}
                handleBackClicked={handleExitDirectory}
                items={items}
              />
            </Box>

            <DndContext
              sensors={sensors}
              onDragStart={onDragStart}
              onDragEnd={onDragEnd}
            >
              <DirectoryItemList
                setDialogAction={setDialogAction}
                onMove={onMove}
                onTrash={onTrash}
                onCancelUpload={(item: Item) => {
                  setDialogAction({
                    name: CANCEL_UPLOAD,
                    targetItems: [item],
                  })
                }}
                onDuplicate={duplicateCourse}
                isDragging={isDragging}
                {...directoryItemProps}
              />
            </DndContext>
          </ListContainer>
        </Stack>
      </PageLayout>
      {showActionDialog && (
        <ItemActionDialogs
          dialogAction={dialogAction}
          setCheckedItems={setCheckedItems}
          refreshItems={refreshItems}
          refreshLastUpdatedCourse={refreshLastUpdatedCourse}
          directory={directory}
          handleMoveItems={handleMoveItems}
          handleCreateDirectory={handleCreateDirectory}
          handleRenameDirectory={handleRenameDirectory}
          onClose={() => {
            setDialogAction(null)
          }}
        />
      )}
      <ShareCourseDialog
        course={location?.state?.share}
        open={!!location?.state?.share}
        onClose={() => history.replace({ ...location, state: { share: null } })}
      />
    </>
  )
}

export default CoursesPage

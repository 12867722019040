import { useState, useEffect } from "react"
import { getCourses } from "../../../api"
import useGetSections from "../../microlearning/hooks/useGetSections"
import createHTMLFromCourseSections from "../api/createHTMLFromCourseSections"
import { Section } from "../../section"

const fetchCourseErrorMessage =
  "There was an issue fetching your list of courses. Please try again and contact LearnExperts if the problem persists."

const createHTMLFromSectionsError =
  "There was an issue adding content to your course. Please try again and contact LearnExperts if the problem persists."

/**
 * Hook for handling state logic of import existing course dialog
 * @param onInsertHTML - Callback function when new HTML is generated for editor
 * @param currentCourseID - ID of course we are adding content to
 */
const useImportExistingCourseSource = (
  onInsertHTML: (html: string) => void,
  currentCourseID: string
) => {
  const [availableCourses, setAvailableCourses] = useState([])
  const [selectedCourseID, setSelectedCourseID] = useState<string | undefined>(
    undefined
  )
  const [useFullCourse, setUseFullCourse] = useState<boolean>(true)
  const [headingLevel, setHeadingLevel] = useState<string>("")
  const [availableSections, setAvailableSections] = useState<Section[]>([])
  const [checkedSections, setCheckedSections] = useState<string[]>([])
  const [error, setError] = useState<string | null>(null)
  const [loadingHTML, setLoadingHTML] = useState<boolean>(false)

  /**
   * useEffect to fetch all existing courses, grab their titles and load into
   * available Courses
   */
  useEffect(() => {
    if (!currentCourseID) {
      return
    }
    getCourses()
      .then(({ data }: any) => {
        // Display all available courses except for the one we are currently editing
        const courses = data.items.filter(
          (course: any) => course.id !== currentCourseID
        )
        setError(null)
        setAvailableCourses(courses)
      })
      .catch((error: any) => {
        console.log(error)
        setError(fetchCourseErrorMessage)
      })
  }, [currentCourseID])

  const { fetchSections, availableHeadings } = useGetSections(selectedCourseID)

  /**
   * Ensure the first available heading option is defaulted
   */
  useEffect(() => {
    if (!selectedCourseID || availableHeadings.length === 0) {
      return
    }
    setHeadingLevel(availableHeadings[0].value)
  }, [availableHeadings, selectedCourseID])

  /**
   * Load available sections of document for a given heading level.
   * Reload sections each time heading level changes.
   *
   */
  useEffect(() => {
    if (useFullCourse || headingLevel === "") {
      return
    }

    fetchSections(headingLevel).then((sections: Section[] | null) => {
      if (!sections) {
        return null
      }
      setAvailableSections(sections)

      // When loading available sections, check them all intially
      setCheckedSections(sections.map((section: Section) => section.key))
    })
  }, [fetchSections, headingLevel, useFullCourse])

  /**
   * Handler for when section(s) from course are selected
   */
  const handleAddSource = () => {
    if (selectedCourseID == null) {
      return
    }
    setLoadingHTML(true)

    createHTMLFromCourseSections({
      courseID: selectedCourseID,
      checkedSections,
      useFullCourse,
    })
      .then((html) => {
        setError(null)
        setLoadingHTML(false)
        onInsertHTML(html)
      })
      .catch((error) => {
        console.log(error)
        setLoadingHTML(false)
        setError(createHTMLFromSectionsError)
      })
  }

  return {
    availableCourses,
    setAvailableCourses,
    selectedCourseID,
    setSelectedCourseID,
    useFullCourse,
    setUseFullCourse,
    headingLevel,
    setHeadingLevel,
    availableSections,
    handleAddSource,
    checkedSections,
    setCheckedSections,
    availableHeadings,
    error,
    loadingHTML,
  }
}

export default useImportExistingCourseSource

/* eslint-disable react/jsx-filename-extension */
import { useEffect } from "react"
import GlobalConfig from "react-global-configuration"
import { Route, Switch, useHistory, useLocation } from "react-router-dom"
import config from "../config"
import Login from "./Login"
import { FroalaEditorPage } from "./FroalaEditorPage"
import CoursesPage from "./CoursesPage"
import ExportPage from "./ExportPage"
import FindPage from "./FindPage"
import SharePage from "./SharePage"
import TrashPage from "../features/trash/TrashPage"
import IntelligentUpdatePage from "./IntelligentUpdatePage"
import HistoryPage from "./HistoryPage"
import HistoryDetailsPage from "./HistoryDetailsPage"
import GiveCoursePage from "./GiveCoursePage"
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles"
import muiTheme from "../themes/theme"
import CreateCoursePage from "./CreateCoursePage"
import useNotifier from "../hooks/useNotifier"
import CreationProgressPage from "./CreationProgressPage"
import useWatchJobs from "../hooks/useWatchJobs"
import ToolsPage from "./ToolsPage"
import BuildMetaPage from "./BuildMetaPage"
import { CssBaseline } from "@mui/material"
import PrivateRoute from "../components/atoms/PrivateRoute"
import ReadonlyEditorPage from "./ReadonlyEditorPage"
import BrandPage from "./BrandPage"
import { useFeatureDecisions } from "../contexts/features"
import HelpPage from "./HelpPage"
import BrandingPage from "./BrandingPage"
import MicrolearningPage from "./MicrolearningPage"
import { TranslationRulesPage } from "./TranslationRulesPage"

import "../features/authentication/bootstrap"
import { useFlag, useIdentify } from "../utilities/feature-management"
import updatedTheme from "../themes/updatedTheme"

import { useAuth } from "../contexts/authorization"
import { addUserMetadata } from "../rum-config"
import useNetworkError from "../hooks/useNetworkError"
import NewCourseScreenUX from "../features/uxRefresh/pages/coursesPage/CoursesPage"
import LearningPathsPage from "../features/uxRefresh/pages/learningPathsPage/LearningPathsPage"
import NewBrandingScreenUX from "../features/uxRefresh/pages/brandingPage/BrandingPage"
import NewTrashScreenUX from "../features/uxRefresh/pages/trashPage/TrashPage"
import mixpanel from "mixpanel-browser"
import {
  useInitializeMixpanelUser,
  useMixpanelNavigationTracker,
} from "../utilities/mixpanel"
import NewTranslationScreenUX from "../features/uxRefresh/pages/translationRules/TranslationRulesPage"
import AuditPage from "../features/uxRefresh/pages/auditPage/AuditPage"
import AuditDetailsPage from "../features/uxRefresh/pages/auditPage/AuditDetailsPage"
import NewMicrolearningScreenUX from "../features/uxRefresh/pages/microlearningPage/MicrolearningPage"
import useRefreshBrowser from "../hooks/useRefreshBrowser"

GlobalConfig.set(config)

mixpanel.init(config.mixpanelApiCredentials.apiKey, {
  debug: true,
  track_pageview: true,
  persistence: "localStorage",
})

/**
 * Renders the LearnExperts web app.
 */
const App = () => {
  useNetworkError()
  useIdentify()
  useNotifier()
  useWatchJobs()
  // Initialize Mixpanel user tracking
  useInitializeMixpanelUser()
  useMixpanelNavigationTracker()
  useRefreshBrowser()
  const decisions = useFeatureDecisions()

  useEffect(() => {
    document.title = GlobalConfig.get("title")
  }, [])

  const { user } = useAuth()

  // When the user changes, this hook updates the AWS RUM metadata to reflect changes
  useEffect(() => {
    const userId = user ? user.id : "User ID not available"

    /**
     * Output user data when mounting
     */
    addUserMetadata(userId)
  }, [user])

  const useNewUX = useFlag("rollout-new-home-page-ux")
  const newMicrolearning = useFlag("rollout-refreshed-microlearning-ux")

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={useNewUX ? updatedTheme : muiTheme}>
        <CssBaseline />
        <Switch>
          <PrivateRoute
            exact
            path="/"
            component={useNewUX ? NewCourseScreenUX : CoursesPage}
          />
          <PrivateRoute
            exact
            path="/creating-course"
            component={CreationProgressPage}
          />
          <PrivateRoute exact path="/export" component={ExportPage} />
          <PrivateRoute
            exact
            path="/microlearning"
            component={
              newMicrolearning ? NewMicrolearningScreenUX : MicrolearningPage
            }
          />
          <PrivateRoute
            exact
            path="/create-course"
            component={CreateCoursePage}
          />
          {/**
           * On the new UX, we don't require the tools page, so only allow it as a route with the old UX
           */}
          {!useNewUX && (
            <PrivateRoute exact path="/tools" component={ToolsPage} />
          )}
          <PrivateRoute exact path="/editor" component={FroalaEditorPage} />
          <PrivateRoute
            exact
            path="/intelligent-update"
            component={IntelligentUpdatePage}
          />
          <PrivateRoute
            exact
            path="/history"
            component={useNewUX ? AuditPage : HistoryPage}
          />
          <PrivateRoute
            exact
            path="/history-details"
            component={useNewUX ? AuditDetailsPage : HistoryDetailsPage}
          />
          <PrivateRoute exact path="/find" component={FindPage} />
          <PrivateRoute exact path="/share" component={SharePage} />
          <PrivateRoute exact path="/give" component={GiveCoursePage} />
          <PrivateRoute
            exact
            path="/trash"
            component={useNewUX ? NewTrashScreenUX : TrashPage}
          />
          <PrivateRoute exact path="/help" component={HelpPage} />
          <PrivateRoute
            exact
            path="/branding"
            component={useNewUX ? NewBrandingScreenUX : BrandingPage}
          />
          <PrivateRoute
            exact
            path="/translation-rules"
            component={useNewUX ? NewTranslationScreenUX : TranslationRulesPage}
          />
          <Route key="login" exact path="/login" component={Login} />
          <Route exact path="/meta/build" component={BuildMetaPage} />
          <Route path="/share/:courseId" component={ReadonlyEditorPage} />
          <PrivateRoute
            exact
            path="/brand"
            component={decisions.enableBrand() ? BrandPage : CoursesPage}
          />
          {/**
           * New routes for refreshed UX feature flag
           */}
          {useNewUX && (
            <>
              <PrivateRoute exact path="/paths" component={LearningPathsPage} />
            </>
          )}
        </Switch>
      </ThemeProvider>
    </StyledEngineProvider>
  )
}

export default App

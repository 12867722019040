import {
  Button,
  CircularProgress,
  ButtonGroup,
  Menu,
  MenuItem,
} from "@mui/material"
import React, { useRef, useState } from "react"
import { uploadTranslationSheet } from "../../api"
import { useAuth } from "../../contexts/authorization"
import FileUploadIcon from "@mui/icons-material/FileUpload"
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"
import GoogleDriveIcon from "@mui/icons-material/Google"
import useGoogleDrivePicker from "../createCourse/hooks/useGoogleDrivePicker"
import {
  downloadDriveUploadItem,
  getGoogleDriveFilesFromUploadItems,
  UploadItem,
} from "../../utilities/fileUpload"
import { useGapi } from "../../contexts/GapiProvider"
import GoogleDriveAccessDialog from "../createCourse/GoogleDriveAccessDialog"
import { useFlag } from "../../utilities/feature-management"
import { MimeTypeCategory } from "../../config"
/**
 * Uploads a translation sheet for a course in a given language.
 * @param props Props for the component.
 * @param props.courseId - The ID of the course.
 * @param props.language - The language to upload the translation sheet for.
 * @returns React component
 */
export function UploadTranslationSheetButton(props: {
  courseId: string
  language: string
}) {
  const { courseId, language } = props
  const [isUploading, setIsUploading] = useState(false)
  const fileInputRef = useRef<HTMLInputElement>(null)
  const rolloutTranslationToGoogleDrive = useFlag(
    "rollout-translation-export-to-google-drive"
  )

  const {
    user: { id: tenantId },
  } = useAuth() as any

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const anchorRef = useRef<HTMLDivElement>(null)
  const [
    googleDriveAccessPromptDialogOpen,
    setGoogleDriveAccessPromptDialogOpen,
  ] = useState(false)
  const { downloadDriveFile } = useGapi()

  /**
   * Toggle the emenu
   * @param event - mouse event
   */
  const handleToggle = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  /**
   * Close the menu
   */
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleMenuItemClick =
    // eslint-disable-next-line jsdoc/require-jsdoc
    (callback: () => void) => (e: React.MouseEvent) => {
      e.preventDefault()
      callback()
      handleClose()
    }

  /**
   * Handles the upload of the translation sheet.
   * @param event - The change event.
   */
  const handleUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    if (!file) return

    setIsUploading(true)
    try {
      await uploadTranslationSheet(tenantId, courseId, language, file)
      alert("Translation sheet uploaded successfully")
    } catch (error) {
      alert("Failed to upload translation sheet")
    } finally {
      setIsUploading(false)
      if (fileInputRef.current) {
        fileInputRef.current.value = ""
      }
    }
  }

  /**
   * Handles the button click.
   */
  const handleButtonClick = () => {
    fileInputRef.current?.click()
  }

  /**
   * Called when google drive files are selected
   * @param uploadItems - selected items
   */
  const onGoogleDriveDocumentAccepted = async (uploadItems: UploadItem[]) => {
    setIsUploading(true)
    try {
      await downloadFromGoogleDrive(uploadItems)
      alert("Translation sheet uploaded successfully")
    } catch (ex) {
      alert("Failed to upload translation sheet from google drive")
    } finally {
      setIsUploading(false)
    }
  }

  /**
   * Download image from google drive
   * @param uploadItems - list of selections
   */
  const downloadFromGoogleDrive = async (
    uploadItems: UploadItem[]
  ): Promise<string | undefined> => {
    const fileObjects = getGoogleDriveFilesFromUploadItems(uploadItems)
    if (fileObjects.length > 0) {
      const uploadFile = fileObjects[0]
      console.log(fileObjects)

      let errorMessage = await downloadDriveUploadItem(
        uploadFile,
        downloadDriveFile
      )

      if (errorMessage != null) {
        // try a second time - google drive sometimes fails on the first try
        errorMessage = await downloadDriveUploadItem(
          uploadFile,
          downloadDriveFile
        )
      }

      if (errorMessage != null) {
        return errorMessage
      } else {
        await uploadTranslationSheet(
          tenantId,
          courseId,
          language,
          uploadFile.file
        )
      }
    }
  }

  const { openDrivePicker, onAccessPermitted } = useGoogleDrivePicker({
    onAccepted: onGoogleDriveDocumentAccepted,
    setGoogleDriveAccessPromptDialogOpen,
    mimeTypeCategories: [MimeTypeCategory.SHEET],
  })

  /** Called on Allow access to Google Drive */
  const handleOkGoogleDriveAccessPromptDialog = () => {
    setGoogleDriveAccessPromptDialogOpen(false)
    onAccessPermitted()
  }

  return (
    <div>
      <ButtonGroup
        variant="outlined"
        ref={anchorRef}
        disabled={isUploading}
        aria-label="split button"
        sx={{
          boxShadow: "none",
        }}
      >
        <input
          type="file"
          ref={fileInputRef}
          onChange={handleUpload}
          style={{ display: "none" }}
          accept=".xlsx"
        />
        <Button
          variant="outlined"
          onClick={handleButtonClick}
          disabled={isUploading}
          startIcon={<FileUploadIcon />}
        >
          {isUploading ? (
            <>
              <CircularProgress size={24} color="inherit" />
              Uploading...
            </>
          ) : (
            "Import Translation Sheet"
          )}
        </Button>
        {rolloutTranslationToGoogleDrive ? (
          <Button
            data-cy="export-screen-export-course-button-menu"
            size="small"
            onClick={handleToggle}
            disabled={isUploading}
            sx={{
              px: 0.5,
              minWidth: "auto",
            }}
          >
            <ArrowDropDownIcon />
          </Button>
        ) : (
          <></>
        )}
      </ButtonGroup>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem onClick={handleMenuItemClick(() => openDrivePicker())}>
          <GoogleDriveIcon sx={{ mr: 1 }} />
          From Google Drive (Beta)
        </MenuItem>
      </Menu>
      <GoogleDriveAccessDialog
        open={googleDriveAccessPromptDialogOpen}
        onClose={() => setGoogleDriveAccessPromptDialogOpen(false)}
        onOk={handleOkGoogleDriveAccessPromptDialog}
      />
    </div>
  )
}

import { useMemo } from "react"
import { useAppDispatch } from "../store"
import {
  fromCourseResponse,
  pending,
  setGuestEmail,
  refreshComments,
} from "../store/remarks"
import { fetchDetections } from "../store/remarks/actions/fetchDetections"
import { fetchSectionDetections } from "../store/remarks/actions/fetchSectionDetections"
import { debounce, throttle } from "lodash"
import { stripTemporaryElements } from "../utilities/smartTemplates"
import { useFlag } from "../utilities/feature-management"

/**
 * Use the set of action dispatchers for manipulating remarks.
 */
const useRemarksDispatch = () => {
  const dispatch = useAppDispatch()

  const interactiveComponentsEnabled = useFlag(
    "rollout-interactive-component-detections"
  )

  return useMemo(() => {
    // Throttle wrapper for regular detections
    const throttledRegularDetections = throttle(
      (html, readOnly) => {
        // Strip temporary elements before dispatching
        const cleanedHtml = stripTemporaryElements(html)
        dispatch(fetchDetections({ html: cleanedHtml, readOnly }))
      },
      15000, // 15 seconds
      { leading: true } // Perform the first call immediately
    )

    // Regular detections with 1 second debounce and 3 second maxWait, then throttled to once per 15 seconds
    const fetchRegularDetectionsDebounced = debounce(
      (html, readOnly) => {
        throttledRegularDetections(html, readOnly)
      },
      1000,
      { maxWait: 3000 }
    )

    // Throttle wrapper for section detections
    const throttledSectionDetections = throttle(
      (html, readOnly) => {
        // Strip temporary elements before dispatching
        const cleanedHtml = stripTemporaryElements(html)
        dispatch(fetchSectionDetections({ html: cleanedHtml, readOnly }))
      },
      120000, // 2 minutes
      { leading: true } // Perform the first call immediately
    )

    // Section detections with 5 second debounce, then throttled to once per 2 minutes
    const fetchSectionDetectionsDebounced = debounce((html, readOnly) => {
      throttledSectionDetections(html, readOnly)
    }, 5000)

    return {
      /**
       * Reset the remarks state pending new course data.
       *
       * @param {string} courseId
       */
      pending: (courseId) => {
        dispatch(pending({ courseId }))
      },

      /**
       * Sets the guest email if a user is unauthenticated
       *
       * @param {string} email
       */
      setGuestEmail: (email) => {
        dispatch(setGuestEmail({ email }))
      },

      /**
       * Update the remarks state from a given course response.
       *
       * @param {Object} response
       */
      fromCourseResponse: (response) => {
        dispatch(
          fromCourseResponse({
            data: response.data,
            status: response.status,
          })
        )
      },

      /**
       * Refresh the comments in the remarks state.
       */
      refreshComments: () => {
        dispatch(refreshComments())
      },

      /**
       * Fetch updated detections for the given html.
       *
       * @param {string} html
       * @param {boolean} readOnly
       */
      fetchDetections: (html, readOnly) => {
        fetchRegularDetectionsDebounced(html, readOnly)
        if (interactiveComponentsEnabled) {
          fetchSectionDetectionsDebounced(html, readOnly)
        }
      },
    }
  }, [dispatch, interactiveComponentsEnabled])
}

export default useRemarksDispatch

import { DEFAULT_POLL_INTERVAL } from "../utils/constants"
import * as api from "../../../api"
import { ExportJobResponse } from "../../../api/fetchExportStatus"

/**
 * Wait for a ML export job to finish
 * @param jobId - job id
 * @param options - { completeStatus, failedStatus, interval }
    }
 */
const waitForExportJob = (
  jobId: string,
  options: any
): Promise<ExportJobResponse> => {
  const {
    completeStatus = "finished",
    failedStatus = "failed",
    interval = DEFAULT_POLL_INTERVAL,
  } = options

  return new Promise((resolve, reject) => {
    /** Periodically check if the job is complete*/
    const checkJob = async () => {
      try {
        const job = await api.fetchExportStatus(jobId)

        if (!job) {
          return reject(new Error("Export job not found"))
        }

        if (job.status === completeStatus) {
          return resolve(job)
        }

        if (job.status === failedStatus) {
          return reject(new Error(job.message || "Export failed"))
        }
      } catch (err) {
        return reject(new Error("Error checking export status."))
      }

      setTimeout(() => {
        checkJob()
      }, interval)
    }

    checkJob()
  })
}

export default waitForExportJob

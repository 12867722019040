import axios from "../../../api/axios"
import { GoogleWorkspaceType } from "../ExportTypes"

/** Result of a successful Google Drive file upload */
export interface GoogleDriveUploadResult {
  /** The Google Drive file ID */
  fileId: string
  /** URL to view the file in Google Drive's web interface */
  webViewLink: string | null
  /** URL for direct folder view */
  folderLink: string

  fileName: string
  folderName: string
}

/**
 * Upload a file from a URL to Google Drive
 * @param {string | Blob} file - The URL of the file to upload
 * @param {string} folderId - The Google Drive folder ID to upload to
 * @param {string} folderName - The name of the Google Drive folder (for logging)
 * @param {string} fileName - The desired filename for the uploaded file
 * @param {GoogleWorkspaceType} saveTo - slides, docs, or just drive
 * @returns {Promise<GoogleDriveUploadResult>} - The ID of the uploaded file in Google Drive
 */
export async function uploadUrlToGoogleDrive(
  file: string | Blob,
  folderId: string,
  folderName: string,
  fileName: string,
  saveTo: GoogleWorkspaceType = "drive"
): Promise<GoogleDriveUploadResult | null> {
  try {
    // Download the file
    let fileContent, fileType
    if (typeof file == "string") {
      const response = await axios.get(file, {
        responseType: "blob",
        withCredentials: true,
      })
      fileContent = response.data
      fileType = response.data.type
    } else {
      fileContent = file
      fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    }

    // Determine mime types based on save target
    const mimeType = getMimeType(saveTo, fileName)

    // Create file metadata
    const metadata = {
      name: fileName,
      parents: [folderId],
      ...(mimeType && { mimeType }),
    }

    // Create boundary and delimiters
    const boundary = "-------314159265358979323846"
    const delimiter = `--${boundary}\r\n` // Note: No leading \r\n here!
    const close_delim = `\r\n--${boundary}--`

    // Create the multipart request body EXACTLY as Google expects
    const multipartRequestBody =
      // First part - metadata
      delimiter +
      "Content-Type: application/json; charset=UTF-8\r\n\r\n" +
      JSON.stringify(metadata) +
      "\r\n" +
      // Second part - file content
      delimiter +
      `Content-Type: ${fileType}\r\n` +
      "Content-Transfer-Encoding: base64\r\n\r\n" +
      (await blobToBase64(fileContent)) +
      close_delim

    // Upload to Google Drive using gapi
    const driveResponse = await gapi.client.request({
      path: "https://www.googleapis.com/upload/drive/v3/files",
      method: "POST",
      params: { uploadType: "multipart" },
      headers: {
        "Content-Type": `multipart/related; boundary=${boundary}`,
      },
      body: multipartRequestBody,
    })

    if (driveResponse.status !== 200) {
      throw new Error(`Upload failed with status: ${driveResponse.status}`)
    }

    const result = JSON.parse(driveResponse.body)
    console.log(
      `File "${fileName}" uploaded successfully to folder "${folderName}" (${folderId})`
    )

    // Return both the file ID and a direct link to the file
    return {
      fileId: result.id,
      webViewLink: getWebViewLink(result.id, saveTo, fileName),
      folderLink: `https://drive.google.com/drive/folders/${folderId}`,
      folderName,
      fileName,
    }
  } catch (error) {
    console.error("Error uploading file to Google Drive:", error)
    return null
  }
}

/**
 * Convert a Blob to a base64 string
 * @param blob - to convert
 */
async function blobToBase64(blob: Blob): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    // eslint-disable-next-line jsdoc/require-jsdoc
    reader.onloadend = () => {
      if (typeof reader.result === "string") {
        // Remove the "data:*/*;base64," prefix
        resolve(reader.result.split(",")[1])
      } else {
        reject(new Error("Failed to convert blob to base64"))
      }
    }
    reader.onerror = reject
    reader.readAsDataURL(blob)
  })
}

/**
 * Set up mime types for automatic conversion
 * @param saveTo - save to target
 * @param fileName - name of file with extension
 */
function getMimeType(
  saveTo: GoogleWorkspaceType,
  fileName: string
): string | null {
  if (saveTo === "slides") {
    return "application/vnd.google-apps.presentation"
  } else if (fileName.endsWith(".docx")) {
    return "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
  } else if (fileName.endsWith(".epub")) {
    return "application/epub+zip"
  }

  return null
}

/**
 * Make a web view link based on target
 * @param fileId - exported file id
 * @param saveTo - save to target
 * @param fileName - name of file with extension
 */
function getWebViewLink(
  fileId: string,
  saveTo: GoogleWorkspaceType,
  fileName: string
): string | null {
  if (saveTo === "slides") {
    return `https://docs.google.com/presentation/d/${fileId}/edit`
  } else if (fileName.endsWith(".docx")) {
    return `https://docs.google.com/document/d/${fileId}/edit`
  }

  return null
}

import { Item } from "../../../homeNavigation/types"

export type UseDirectoryItemsProps = {
  refreshItems: () => void
  items: Item[]
  query: string
  loading: boolean
  directory: string | null
  checkedItems: string[]
  setCheckedItems: React.Dispatch<React.SetStateAction<string[]>>
  handleItemSelected: (event: any, item: Item) => void
  handleMoveItems: (items: Item[], destinationFolder: Item) => void
  handleCreateDirectory: (name: string) => void
  handleRenameDirectory: (id: string, newName: string) => void
  handleExitDirectory: () => void
  setQuery: (query: string) => void
  currentFolder: string | null
  handleCreateCourseClicked: (e: any) => void
  handleSelectSortOption: (option: string) => void
  sortBy: string
  displaySearchResults: boolean
  folderTitleMap: {
    [key: string]: string
  }
  highlight?: boolean
}

export type DirectoryItemListProps = UseDirectoryItemsProps & {
  setDialogAction: (action: DialogAction | null) => void
  onMove: (ids: string[]) => void
  onTrash: (ids: string[]) => void
  onCancelUpload: (item: Item) => void
  onDuplicate: (item: Item) => void
  isDragging: boolean
}

export type DirectoryItemProps = DirectoryItemListProps & {
  item: Item
  onUploadImage: (item: Item) => void
  onRenameFolder: (item: Item) => void
}

export type DirectoryItemActionDialogsProps = {
  dialogAction: DialogAction | null
  setCheckedItems: React.Dispatch<React.SetStateAction<string[]>>
  refreshItems: () => void
  refreshLastUpdatedCourse: () => void
  directory: string | null
  handleMoveItems: (items: Item[], destinationFolder: Item) => void
  handleCreateDirectory: (name: string) => void
  handleRenameDirectory: (id: string, newName: string) => void
  onClose: () => void
}

export enum DialogActionName {
  TRASH = "TRASH",
  CANCEL_UPLOAD = "CANCEL_UPLOAD",
  UPLOAD_IMAGE = "UPLOAD_IMAGE",
  MOVE_COURSE = "MOVE_COURSE",
  CREATE_FOLDER = "CREATE_FOLDER",
  RENAME_FOLDER = "RENAME_FOLDER",
}

export type DialogAction = {
  name: DialogActionName
  targetItems: Item[] | null
}

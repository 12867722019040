import axiosGlobal from "./axios"

interface ExportFile {
  url: string
  filename: string
}

/**
 * Create a zip containing multiple microlearning export files
 * @param courseId - the course
 * @param files - array of export files with urls and filenames
 * @param axios - axios
 */
const createMicroLearningZip = async (
  courseId: string,
  files: ExportFile[],
  axios = axiosGlobal
): Promise<string> => {
  const response = await axios.post(`/microlearning/zip/${courseId}`, {
    files,
  })
  return response.data.export_url
}

export default createMicroLearningZip

import useUploadByID from "../../../../../hooks/useUploadByID"
import { union, without } from "lodash/fp"
import { getTitle, getLastUpdated } from "../../../../../utilities/transformers"
import CourseContextMenu from "../../../../welcome/CourseContextMenu"
import { CourseCancelUploadIconButton } from "../../../../../components/widgets/CourseCancelUploadControl"
import { CourseWrapper, FolderWrapper } from "./DirectoryItemWrappers"
import ListItemContent from "../../../itemList/ListItemContent"
import { CircularProgress, ListItem } from "@mui/material"
import DirectoryItemAvatar from "./DirectoryItemAvatar"
import { DirectoryItemProps } from "../types"

/**
 * Component for displaying clickable Course or Folder item
 * @param props - Component props
 */
const DirectoryItem = (props: DirectoryItemProps) => {
  const {
    item,
    checkedItems,
    setCheckedItems,
    handleItemSelected,
    onTrash,
    onDuplicate,
    onCancelUpload,
    onUploadImage,
    onMove,
    onRenameFolder,
    displaySearchResults,
    folderTitleMap = {},
    highlight,
  } = props

  const { last_update, item_type, parent_directory, isLoading } = item
  const { stage } = useUploadByID(item.id)

  const DirectoryItemWrapper =
    item_type === "folder"
      ? FolderWrapper
      : !isLoading
      ? CourseWrapper
      : ListItem

  const parentFolderTitle = parent_directory
    ? folderTitleMap[parent_directory]
    : null

  return (
    <DirectoryItemWrapper
      item={item}
      onClick={(e: any) => {
        if (isLoading) {
          return
        }
        handleItemSelected(e, item)
      }}
      divider
    >
      <ListItemContent
        sx={{
          animation: highlight ? "pulse 1s infinite alternate" : "none",
          "@keyframes pulse": {
            "0%": { backgroundColor: "#f0f8ff" },
            "50%": { backgroundColor: "transparent" },
            "100%": { backgroundColor: "#f0f8ff" },
          },
        }}
        avatar={
          !isLoading ? (
            <DirectoryItemAvatar
              item={item}
              checkedItems={checkedItems}
              handleCourseChecked={(event: any, id: string) => {
                setCheckedItems((prev: string[]) =>
                  event.target.checked ? union(prev, [id]) : without([id], prev)
                )
              }}
            />
          ) : (
            <CircularProgress />
          )
        }
        primaryText={getTitle(item.title)}
        secondaryText={
          !displaySearchResults
            ? !isLoading
              ? getLastUpdated(last_update)
              : stage.caption ?? ""
            : parentFolderTitle ?? "All Courses"
        }
        control={
          !isLoading ? (
            <CourseContextMenu
              document={item}
              onTrash={onTrash}
              onDuplicate={onDuplicate}
              isList
              onUploadImage={onUploadImage}
              onMove={onMove}
              onRenameFolder={onRenameFolder}
            />
          ) : (
            <CourseCancelUploadIconButton
              onCancel={() => onCancelUpload(item)}
            />
          )
        }
      />
    </DirectoryItemWrapper>
  )
}

export default DirectoryItem

type VideoPlatform = {
  regex: RegExp
  embedUrl: string
  customParser?: (url: string) => string | null
}

/**
 * Custom parser for YouTube URLs to handle various formats.
 * @param url - YouTube URL
 */
const extractYouTubeID = (url: string) => {
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/
  const match = url.match(regExp)
  return match && match[2].length === 11 ? match[2] : null
}

/**
 * Extracts the video ID using the provided regex pattern.
 * @param url - URL of the video
 * @param regex - Regex pattern to extract the ID
 */
const extractID = (url: string, regex: RegExp) => {
  const match = url.match(regex)
  return match ? match[1] : null
}

/**
 * Extracts the Vimeo video ID using the provided regex pattern.
 * @param url - URL of the Vimeo video
 */
const extractVimeoID = (url: string) => {
  const regex = /https:\/\/vimeo\.com\/(\d+)\/?(\w+)?/
  const match = url.match(regex)
  if (match) {
    return match[1] + (match[2] ? `?h=${match[2]}` : "")
  }
  return null
}
/**
 * Configuration for supported video platforms.
 */
export const whiteListedVideoPlatforms: {
  [key: string]: VideoPlatform | null
} = {
  "youtube.com": {
    regex: /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/,
    embedUrl: "https://www.youtube.com/embed/{id}",
    customParser: extractYouTubeID,
  },
  "youtu.be": {
    regex: /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/,
    embedUrl: "https://www.youtube.com/embed/{id}",
    customParser: extractYouTubeID,
  },
  "wistia.com": {
    regex: /https:\/\/[\w-]+\.wistia\.com\/medias\/(\w+)/,
    embedUrl: "https://fast.wistia.net/embed/iframe/{id}",
  },
  "fast.wistia.net": {
    regex: /https:\/\/fast\.wistia\.net\/embed\/iframe\/(\w+)/,
    embedUrl: "https://fast.wistia.net/embed/iframe/{id}",
  },
  "vimeo.com": {
    regex: /https:\/\/vimeo\.com\/(\d+)\/?(\w+)?/,
    embedUrl: "https://player.vimeo.com/video/{id}",
    customParser: extractVimeoID,
  },
  "vidyard.com": {
    regex: /https:\/\/share\.vidyard\.com\/watch\/(\w+)/,
    embedUrl: "https://play.vidyard.com/{id}",
  },
  "brightcove.net": null,
  "bcove.video": null,
  "canva.com": {
    regex: /https:\/\/www\.canva\.com\/design\/([\w-]+\/[\w-]+)/,
    embedUrl: "https://www.canva.com/design/{id}/watch?embed",
  },
  "synthesia.io": {
    regex: /https:\/\/share\.synthesia\.io\/([a-f0-9-]+)/,
    embedUrl: "https://share.synthesia.io/embeds/videos/{id}",
  },

  /**
   * TODO: As we continue to add additional whitelisted IFrame sources,
   * we may have to implement extra conditions to detect the URL domain
   * and conditionally construct an embed code.
   */
}

/**
 * Depending on the domain of the URL, the URL itself may not be sufficient
 * as an IFrame source (such as YouTube shareable links). In these cases, we
 * need to detect the domain and create a embed code from the URL
 * @param url - URL of video inputted
 */
export const generateEmbedSource = (url: string) => {
  const [domain, platform] = Object.entries(whiteListedVideoPlatforms).find(
    ([domain, _]) => url.includes(domain)
  ) ?? [null, null]

  if (!domain) {
    return null
  }

  // If the platform is null, the format is whitelisted but requires no additional parsing
  if (!platform) {
    return url
  }

  const id = platform.customParser
    ? platform.customParser(url)
    : extractID(url, platform.regex)
  return id ? platform.embedUrl.replace("{id}", id) : null
}

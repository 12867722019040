import {
  Button,
  ButtonGroup,
  CircularProgress,
  Menu,
  MenuItem,
} from "@mui/material"
import { useRef, useState } from "react"
import { downloadTranslationSheet } from "../../api"
import { useAuth } from "../../contexts/authorization"
import FileDownloadIcon from "@mui/icons-material/FileDownload"
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"
import GoogleDriveIcon from "@mui/icons-material/Google"
import { GoogleWorkspaceType } from "./ExportTypes"
import { useFlag } from "../../utilities/feature-management"

/**
 * Downloads a translation sheet for a course in a given language, retrying until it is completed.
 * @param props Props for the component.
 * @param props.courseId - The ID of the course.
 * @param props.language - The language to download the translation sheet for.
 * @param props.courseHtml - The HTML content of the course.
 * @param props.summaryHtml - The HTML content of the summary.
 * @param props.onTranslationExportToGoogle - The onTranslationExportToGoogle event
 * @param props.isDownloadingTranslation - boolean value for translation sheet download state
 * @returns React component
 */
export function DownloadTranslationSheetButton(props: {
  courseId: string
  language: string
  courseHtml: string
  summaryHtml: string
  onTranslationExportToGoogle: (saveTo: GoogleWorkspaceType) => void
  isDownloadingTranslation: boolean
}) {
  const {
    courseId,
    language,
    courseHtml,
    summaryHtml,
    onTranslationExportToGoogle,
    isDownloadingTranslation,
  } = props
  const [isDownloading, setIsDownloading] = useState(false)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const rolloutTranslationToGoogleDrive = useFlag(
    "rollout-translation-export-to-google-drive"
  )

  const {
    user: { id: tenantId },
  } = useAuth() as any
  const anchorRef = useRef<HTMLDivElement>(null)

  /**
   * Toggle the emenu
   * @param event - mouse event
   */
  const handleToggle = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  /**
   * Close the menu
   */
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleMenuItemClick =
    // eslint-disable-next-line jsdoc/require-jsdoc
    (callback: () => void) => (e: React.MouseEvent) => {
      e.preventDefault()
      callback()
      handleClose()
    }

  /**
   * Handles the download of the translation sheet.
   */
  const handleDownload = async () => {
    setIsDownloading(true)
    try {
      while (true) {
        const completed = await downloadTranslationSheet(
          tenantId,
          courseId,
          language,
          courseHtml,
          summaryHtml
        )
        if (completed) {
          break
        }
        await new Promise((resolve) => setTimeout(resolve, 1000))
      }
    } catch (error) {
      alert("Failed to download translation sheet")
    } finally {
      setIsDownloading(false)
    }
  }

  return (
    <div>
      <ButtonGroup
        variant="outlined"
        ref={anchorRef}
        disabled={isDownloading || !courseHtml}
        aria-label="split button"
        sx={{
          boxShadow: "none",
        }}
      >
        <Button
          variant="outlined"
          onClick={handleDownload}
          disabled={isDownloading || !courseHtml || isDownloadingTranslation}
          startIcon={
            isDownloading || isDownloadingTranslation ? (
              <CircularProgress size={24} />
            ) : (
              <FileDownloadIcon />
            )
          }
        >
          Export Translation Sheet
        </Button>
        {rolloutTranslationToGoogleDrive ? (
          <Button
            data-cy="export-screen-export-course-button-menu"
            size="small"
            onClick={handleToggle}
            disabled={isDownloading || !courseHtml || isDownloadingTranslation}
            sx={{
              px: 0.5,
              minWidth: "auto",
            }}
          >
            <ArrowDropDownIcon />
          </Button>
        ) : (
          <></>
        )}
      </ButtonGroup>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem
          onClick={handleMenuItemClick(() =>
            onTranslationExportToGoogle("drive")
          )}
        >
          <GoogleDriveIcon sx={{ mr: 1 }} />
          To Google Drive (Beta)
        </MenuItem>
      </Menu>
    </div>
  )
}

import { exportAsyncStatusEnum } from "../features/export/utils/contants"
import { gateway } from "./axios"
import { EXPORT_API_ENDPOINT } from "./exportEndpoint"

export interface ExportJobResponse {
  tenant_id: string
  job_id: string
  course_id: string
  format: string
  status: typeof exportAsyncStatusEnum
  start_datetime: Date
  export_url: string | null
  package_url: string | null
  message: string | null
}

/**
 * Get export job status from the export service
 * @param jobId - job id
 */
const fetchExportStatus = async (
  jobId: string
): Promise<ExportJobResponse | null> => {
  const response = await gateway.get<ExportJobResponse>(
    EXPORT_API_ENDPOINT + jobId,
    {
      baseURL: "/",
    }
  )
  if (response.status === 200) {
    return response.data
  } else {
    return null
  }
}

export default fetchExportStatus

import axiosGlobal from "./axios"

/**
 * Create a params to start an export from microlearning
 * @param courseId - the course
 * @param format - output format
 * @param axios - axios
 */
const createMicroLearningExportParams = async (
  courseId: string,
  format: string,
  axios = axiosGlobal
): Promise<any> => {
  const response = await axios.get(
    `/microlearning/export/${courseId}/${format}`
  )
  return response.data
}

export default createMicroLearningExportParams

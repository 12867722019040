type Label = {
  /** x-position of label in pixels */
  x: number
  /** y-position of label in pixels */
  y: number
  /** New x-position of label in pixels */
  nx: number
  /** New y-position of label in pixels */
  ny: number

  text: string
}
/**
 * Reposition label if it overlaps
 *
 * @param labels - list of labels
 * @param scale - how much the image is scaled to make it fit
 * @param circleSize - size of the label circle
 */
const repositionOverlappingLabels = (
  labels: Label[],
  scale: number,
  circleSize: number
): { repositionedLabels: Label[]; hasOverlapped: boolean } => {
  const existingLabels = labels.map((label) => ({ ...label }))
  let hasOverlapped = false
  /**
   * Checks if two labels overlap.
   *
   * @param labelA - The first label to compare.
   * @param labelB - The second label to compare.
   * @returns True if the labels overlap, otherwise false.
   */
  const isOverlapping = (labelA: Label, labelB: Label): boolean => {
    const lablesEdge = circleSize / 2
    const boxA = {
      x1: labelA.nx * scale - lablesEdge,
      y1: labelA.ny * scale - lablesEdge,
      x2: labelA.nx * scale + lablesEdge,
      y2: labelA.ny * scale + lablesEdge,
    }

    const boxB = {
      x1: labelB.nx * scale - lablesEdge,
      y1: labelB.ny * scale - lablesEdge,
      x2: labelB.nx * scale + lablesEdge,
      y2: labelB.ny * scale + lablesEdge,
    }

    return (
      boxA.x1 < boxB.x2 &&
      boxA.x2 > boxB.x1 &&
      boxA.y1 < boxB.y2 &&
      boxA.y2 > boxB.y1
    )
  }

  /**
   * Finds a non-overlapping position for a label by adjusting its position.
   *
   * @param currentLabel - The label that needs to be positioned.
   * @param allLabels - An array of existing labels to avoid overlapping with.
   * @param maxAttempts - The maximum number of attempts to reposition the label (default: 100).
   * @returns The adjusted label positioned without overlap.
   */
  const findNonOverlappingPosition = (
    currentLabel: Label,
    allLabels: Label[],
    maxAttempts: number = 100
  ): Label => {
    let newX = currentLabel.nx
    let newY = currentLabel.ny
    let attempts = 0
    // Filter out the current label from allLabels to avoid self-comparison
    const allOtherLabels = allLabels.filter((other) => other !== currentLabel)
    while (attempts < maxAttempts) {
      let overlapFound = false
      // Check for overlap with all other labels
      for (const otherLabel of allOtherLabels) {
        if (
          isOverlapping({ ...currentLabel, nx: newX, ny: newY }, otherLabel)
        ) {
          overlapFound = true
          break
        }
      }

      // If no overlap, return the new position
      if (!overlapFound) {
        return { ...currentLabel, nx: newX, ny: newY }
      }

      // Move the currentLabel in a spiral pattern to find a new position
      const angle = (attempts * Math.PI) / 4 // 45-degree increments
      const radius = 20 * (attempts + 1) // Increase radius with each attempt
      newX = currentLabel.nx + Math.cos(angle) * radius
      newY = currentLabel.ny + Math.sin(angle) * radius

      attempts++
    }

    // If no position found after maxAttempts, return the original position
    return currentLabel
  }

  // Reposition overlapping labels
  for (let i = 0; i < existingLabels.length; i++) {
    for (let j = i + 1; j < existingLabels.length; j++) {
      const labelA = existingLabels[i]
      const labelB = existingLabels[j]
      if (isOverlapping(labelA, labelB)) {
        // Reposition labelB to a non-overlapping position
        existingLabels[j] = findNonOverlappingPosition(labelB, existingLabels)
        hasOverlapped = true
      }
    }
  }

  return { repositionedLabels: existingLabels, hasOverlapped }
}

export default repositionOverlappingLabels

import {
  Button,
  ButtonGroup,
  CircularProgress,
  IconButton,
  List,
  ListItem,
  Menu,
  MenuItem,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material"
import { IBranding, IEditorFont } from "../../../branding/hooks/useBranding"
import { Delete } from "@mui/icons-material"
import { useEffect, useRef, useState } from "react"
import { v4 as uuidv4 } from "uuid"
import loadFontFace from "../../../branding/utils/loadFontFace"
import { useGapi } from "../../../../contexts/GapiProvider"
import {
  downloadDriveUploadItem,
  getGoogleDriveFilesFromUploadItems,
  UploadItem,
} from "../../../../utilities/fileUpload"
import useGoogleDrivePicker from "../../../createCourse/hooks/useGoogleDrivePicker"
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"
import GoogleDriveIcon from "@mui/icons-material/Google"
import GoogleDriveAccessDialog from "../../../createCourse/GoogleDriveAccessDialog"
import { MimeTypeCategory } from "../../../../config"
const CUSTOM_FONT_LIMIT = 3 // Limit custom user fonts

/**
 * Component for allowing users to upload their own custom fonts to be used in
 * the editor
 * @param props - Component props
 */
const EditorCustomFontsControl = (props: any) => {
  const { setBranding, branding } = props

  const fonts = branding.editorFonts

  useEffect(() => {
    if (fonts) {
      fonts.map(async (font: IEditorFont) => {
        await loadFontFace(font)
      })
    }
  }, [fonts])
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const anchorRef = useRef<HTMLDivElement>(null)
  const [isUploading, setIsUploading] = useState(false)

  const [
    googleDriveAccessPromptDialogOpen,
    setGoogleDriveAccessPromptDialogOpen,
  ] = useState(false)

  const handleMenuItemClick =
    // eslint-disable-next-line jsdoc/require-jsdoc
    (callback: () => void) => (e: React.MouseEvent) => {
      e.preventDefault()
      callback()
      handleClose()
    }

  /**
   * Toggle the emenu
   * @param event - mouse event
   */
  const handleToggle = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  /**
   * Close the menu
   */
  const handleClose = () => {
    setAnchorEl(null)
  }

  const { downloadDriveFile } = useGapi()

  /**
   * Called when google drive files are selected
   * @param uploadItems - selected items
   */
  const onGoogleDriveDocumentAccepted = async (uploadItems: UploadItem[]) => {
    setIsUploading(true)
    try {
      await downloadFromGoogleDrive(uploadItems)
    } catch (ex) {
      alert("Failed to upload translation sheet from google drive")
    } finally {
      setIsUploading(false)
    }
  }

  /**
   * Download image from google drive
   * @param uploadItems - list of selections
   */
  const downloadFromGoogleDrive = async (
    uploadItems: UploadItem[]
  ): Promise<string | undefined> => {
    const fileObjects = getGoogleDriveFilesFromUploadItems(uploadItems)
    if (fileObjects.length > 0) {
      const uploadFile = fileObjects[0]
      console.log(fileObjects)

      let errorMessage = await downloadDriveUploadItem(
        uploadFile,
        downloadDriveFile
      )

      if (errorMessage != null) {
        // try a second time - google drive sometimes fails on the first try
        errorMessage = await downloadDriveUploadItem(
          uploadFile,
          downloadDriveFile
        )
      }

      if (errorMessage != null) {
        return errorMessage
      } else {
        if (uploadFile.file instanceof File) {
          const fontUrl = URL.createObjectURL(uploadFile.file)
          const name = uploadFile.file.name.split(".")[0]
          const id = uuidv4()
          const font = new FontFace(name, `url(${fontUrl})`)
          document.fonts.add(font)

          setBranding((prev: IBranding) => ({
            ...prev,
            editorFonts: [
              ...prev.editorFonts,
              {
                id,
                fontUrl,
                name,
              },
            ],
            changes: {
              ...prev.changes,
              addedEditorFonts: [...(prev.changes.addedEditorFonts ?? []), id],
              [id]: uploadFile.file,
            },
          }))
        }
      }
    }
  }

  const { openDrivePicker, onAccessPermitted } = useGoogleDrivePicker({
    onAccepted: onGoogleDriveDocumentAccepted,
    setGoogleDriveAccessPromptDialogOpen,
    mimeTypeCategories: [MimeTypeCategory.FONTS],
  })

  /** Called on Allow access to Google Drive */
  const handleOkGoogleDriveAccessPromptDialog = () => {
    setGoogleDriveAccessPromptDialogOpen(false)
    onAccessPermitted()
  }

  /**
   * Handle file upload of font. Create a URL to display it to the user and
   * store the file object itself in the branding.changes object in the state.
   *
   * @param event - File input event
   */
  const onAdd = (event: any) => {
    if (event.target.files) {
      const file = event.target.files[0]
      const url = URL.createObjectURL(file)
      // For the text content of the font preview, grab whatever name has been given to the font file
      const name = file.name.split(".")[0]
      const id = uuidv4()
      const font = new FontFace(name, `url(${url})`)
      document.fonts.add(font)

      setBranding((prev: IBranding) => ({
        ...prev,
        editorFonts: [
          ...prev.editorFonts,
          {
            id,
            url,
            name,
          },
        ],
        changes: {
          ...prev.changes,
          addedEditorFonts: [...(prev.changes.addedEditorFonts ?? []), id],
          [id]: file,
        },
      }))
    }
  }

  /**
   * Handle the removal of a custom editor font.Slightly different logic based on whether
   * the font was already committed to the database
   * @param font - Font to remove from list
   */
  const onRemove = (font: IEditorFont) => {
    // Check if font was recently added by looking for it in newEditorFonts
    const wasAddedBeforeSave = branding.changes.addedEditorFonts?.includes(
      font.id
    )

    setBranding((prev: IBranding) => ({
      ...prev,
      // Remove font from UI
      editorFonts: prev.editorFonts.filter((f) => f.url !== font.url),

      changes: {
        ...prev.changes,

        // If the font was added and no save has occurred, simply remove it from the list
        ...(wasAddedBeforeSave
          ? {
              addedEditorFonts: prev.changes.addedEditorFonts.filter(
                (addedFont: string) => addedFont !== font.id
              ),
            }
          : // Otherwise, it was a previously saved font being removed, so append it to the removedEditorFonts list
            {
              removedEditorFonts: [
                ...(prev.changes.removedEditorFonts ?? []),
                font.id,
              ],
            }),
      },
    }))
  }

  return (
    <Stack>
      <Typography gutterBottom variant="titleMedium">
        Custom Editor Fonts
      </Typography>

      <List>
        {fonts.map((font: IEditorFont) => (
          <ListItem
            secondaryAction={
              <Tooltip title="Remove">
                <IconButton
                  edge="end"
                  onClick={(e: any) => {
                    e.preventDefault()
                    onRemove(font)
                  }}
                >
                  <Delete />
                </IconButton>
              </Tooltip>
            }
          >
            <Typography sx={{ font: `1rem "${font.name}"` }}>
              {font.name}
            </Typography>
          </ListItem>
        ))}
        {fonts.length < CUSTOM_FONT_LIMIT && (
          <ListItem>
            <Stack
              width="100%"
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <ButtonGroup
                variant="text"
                ref={anchorRef}
                disabled={isUploading}
                aria-label="split button"
                sx={{
                  boxShadow: "none",
                }}
              >
                <Button
                  variant="text"
                  component="label"
                  startIcon={
                    isUploading ? <CircularProgress size={24} /> : <></>
                  }
                >
                  <input
                    hidden
                    type="file"
                    accept=".ttf,.otf,.woff2,.woff"
                    onChange={onAdd}
                  />
                  Add Font
                </Button>
                <Button
                  data-cy="export-screen-export-course-button-menu"
                  size="small"
                  onClick={handleToggle}
                  disabled={isUploading}
                  sx={{
                    px: 0.5,
                    minWidth: "auto",
                  }}
                >
                  <ArrowDropDownIcon />
                </Button>
              </ButtonGroup>
              <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                <MenuItem
                  onClick={handleMenuItemClick(() => openDrivePicker())}
                >
                  <GoogleDriveIcon sx={{ mr: 1 }} />
                  From Google Drive (Beta)
                </MenuItem>
              </Menu>
              <GoogleDriveAccessDialog
                open={googleDriveAccessPromptDialogOpen}
                onClose={() => setGoogleDriveAccessPromptDialogOpen(false)}
                onOk={handleOkGoogleDriveAccessPromptDialog}
              />
            </Stack>
          </ListItem>
        )}
      </List>
    </Stack>
  )
}

export default EditorCustomFontsControl

import waitForExportJob from "./waitForExportJob"
import { microlearningAsyncStatusEnum } from "../utils/constants"

import previewMicroLearningExport, {
  MicrolearningRequest,
} from "./previewMicroLearningExport"
import axios from "../../../api/axios"
import { CourseParams, MicroLearningExportConfig } from "./types"

/**
 * Converts export configuration to the format expected by the API
 * @param config - The export configuration from the UI
 * @param courseParams - The course parameters from the server
 * @returns MicrolearningRequest formatted for the API
 */
const convertConfigToRequest = (
  config: MicroLearningExportConfig,
  courseParams: CourseParams
): MicrolearningRequest => {
  let optionId = config.optionId

  if (optionId == null && config.checkedOptions.length > 0) {
    optionId = config.checkedOptions[0].key
  }
  return {
    stage: courseParams.stage,
    tenant_id: courseParams.tenant_id,
    params: {
      condensePercent: config.condensePercent,
      optionId,
      optionList: config.optionList,
      organizeBy: config.organizeBy,
      transformComponentsToText: config.transformComponentsToText,
      transformMediaToText: config.transformMediaToText,
    },
    course_params: courseParams,
  }
}

/**
 * Generate micro-learning and return HTML for preview
 * @param params - Configuration settings for micro-learning
 */
const getMicroLearningExportPreview = async (
  params: MicroLearningExportConfig
) => {
  const courseParams = await previewMicrolearning(params.courseId)

  const req = convertConfigToRequest(params, courseParams)

  const response = await previewMicroLearningExport(req)

  if (response.status === 200) {
    /**
     * Cached preview was retrieved
     */
    return response.data.preview
  } else if (response.status === 202) {
    /**
     * No cached preview exists for configuration, so wait on async job to create
     * it then re-fetch it
     */
    const jobId = response.data.job_id

    await waitForExportJob(jobId, {
      completeStatus: microlearningAsyncStatusEnum.COMPLETE,
      failedStatus: microlearningAsyncStatusEnum.FAILED_NO_RETRY,
      interval: 1000,
    })
    return await previewMicroLearningExport(req).then(
      ({ data }) => data.preview
    )
  }
}

export default getMicroLearningExportPreview

/**
 * Send request to server to retrieve ML params
 * @param courseId - course to load
 */
const previewMicrolearning = async (
  courseId: string
): Promise<CourseParams> => {
  const { data } = await axios.get(`/microlearning/preview/${courseId}`)
  return data
}

import { gateway } from "./axios"
import { EXPORT_API_ENDPOINT } from "./exportEndpoint"

/**
 * Sent export request to the export service
 * @param exportItem - Object with export details
 */
const startExport = async (exportItem: any) => {
  const resp = await gateway.post<any>(EXPORT_API_ENDPOINT, exportItem, {
    baseURL: "/",
  })

  return resp
}

export default startExport

import axios from "../../../api/axios"
import getMicroLearningExportPreview from "./getMicroLearningExportPreview"
import { MicroLearningExportConfig } from "./types"

/**
 * Process a single exported course
 * @param fileData - ml confil
 * @param learningPathId - learning path to save to, if any
 */
const processSaveCourse = async (
  fileData: MicroLearningExportConfig,
  learningPathId?: string
): Promise<SavedMicroLearningCourse> => {
  // First generate and wait for preview
  const previewResponse = await getMicroLearningExportPreview(fileData)

  const html = previewResponse.html

  // After preview, create the course

  let title
  if (fileData.optionId) {
    // optionId is the selected section or concept for this course
    const matchingOption = fileData.optionList?.find(
      (option) => option.key === fileData.optionId
    )

    title = matchingOption?.label || fileData.optionId
  }

  const newCourseInfo: NewMicroLearningCourse = {
    html,
    title,
    addToLearningPath: !!fileData.createPath,
    folder: fileData.folder,
    learningPathId,
  }

  return saveMicrolearningCourse(fileData.courseId, newCourseInfo)
}

/**
 * call api to create courses
 * @param data - ml config
 */
const saveMicroLearningContent = async (data: MicroLearningExportConfig) => {
  // Determine if we're dealing with multiple files or just one

  const exportCourses =
    data.organizeBy === "FULL_DOCUMENT"
      ? [data]
      : data.checkedOptions.map((option: any) => ({
          ...data,
          optionId: option.key ? option.key : option,
        }))

  let learningPathId
  let saved_courses: string[] = []
  for (const coursedata of exportCourses) {
    const saveResult: SavedMicroLearningCourse = await processSaveCourse(
      coursedata,
      learningPathId
    )
    learningPathId = saveResult.learningPathId
    saved_courses.push(saveResult.courseId)
  }
  return { saved_courses }
}

export default saveMicroLearningContent

interface NewMicroLearningCourse {
  html: string
  title: string | undefined
  addToLearningPath: boolean
  folder: string | undefined
  learningPathId: string | undefined
}

interface SavedMicroLearningCourse {
  learningPathId: string | undefined
  courseId: string
}

/**
 * Send request to server to create ML course
 * @param courseId - source course id
 * @param courseInfo - content and name of course
 */
const saveMicrolearningCourse = async (
  courseId: string,
  courseInfo: NewMicroLearningCourse
): Promise<SavedMicroLearningCourse> => {
  const { data } = await axios.put(
    `/microlearning/save/${courseId}`,
    courseInfo
  )
  return data
}

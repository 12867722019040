import { gateway } from "../../../api/axios"
import { EXPORT_API_ENDPOINT } from "../../../api/exportEndpoint"

interface ConceptData {
  label: string
  word_count: number
  sections: string[]
  synonyms: string[]
}

interface OptionItem {
  key: string
  label: string
  duration?: string
  data?: ConceptData
}

interface MicroLearningPreviewParams {
  condensePercent: number
  optionId?: string
  optionList: OptionItem[]
  organizeBy: "FULL_DOCUMENT" | "SECTION" | "CONCEPT"
  transformComponentsToText: boolean
  transformMediaToText: boolean
}

interface MicrolearningCourseParams {
  course_id: string
  version_id: string
  title: string
  html: string
}

export interface MicrolearningRequest {
  stage: string
  tenant_id: string
  params: MicroLearningPreviewParams
  course_params: MicrolearningCourseParams
}

/**
 * Send request to server to retrieve HTML preview for given page of Micro-Learning
 * @param req - microlearning request
 */
const previewMicroLearningExport = async (req: MicrolearningRequest) => {
  return await gateway.post(
    EXPORT_API_ENDPOINT + "microlearning/preview",
    req,
    {
      headers: {
        "Content-Type": "application/json",
      },
      baseURL: "/",
    }
  )
}

export default previewMicroLearningExport

import {
  Button,
  ButtonGroup,
  CircularProgress,
  Menu,
  MenuItem,
  Stack,
  TextField,
  Theme,
} from "@mui/material"
import { ReactNode, useEffect, useRef, useState } from "react"
import { Autocomplete } from "@mui/lab"
import { IGoogleFont } from "../../../../branding/hooks/useBranding"
import ListBoxComponent from "../../../../branding/ListBoxComponent"
import loadFontFace from "../../../../branding/utils/loadFontFace"
import { useGapi } from "../../../../../contexts/GapiProvider"
import useGoogleDrivePicker from "../../../../createCourse/hooks/useGoogleDrivePicker"
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"
import GoogleDriveIcon from "@mui/icons-material/Google"
import GoogleDriveAccessDialog from "../../../../createCourse/GoogleDriveAccessDialog"
import {
  downloadDriveUploadItem,
  getGoogleDriveFilesFromUploadItems,
  UploadItem,
} from "../../../../../utilities/fileUpload"
import { MimeTypeCategory } from "../../../../../config"

/**
 * Component for uploading and previewing fonts
 * @param props - Component props
 */
const FontSelectionControl = (props: any) => {
  const {
    branding,
    variant = "heading",
    onPropertyChange,
    title,
    googleFonts,
    recentFonts,
    updateRecentFonts,
  } = props

  const selectedFont = branding[variant]

  /**
   * Upon mounting, check to see if we have an existing font file URL loaded into
   * the state. If so, create a custom font face using that file, load it into
   * the document, and apply that font style to the preview text, which will
   * be set to the the font's generated name in the state.
   */
  useEffect(() => {
    if (selectedFont) {
      loadFontFace(selectedFont)
    }
  }, [selectedFont])
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const anchorRef = useRef<HTMLDivElement>(null)
  const fileInputRef = useRef<HTMLInputElement>(null)
  const [isUploading, setIsUploading] = useState(false)

  const [
    googleDriveAccessPromptDialogOpen,
    setGoogleDriveAccessPromptDialogOpen,
  ] = useState(false)

  /**
   * Handles the button click.
   */
  const handleButtonClick = () => {
    fileInputRef.current?.click()
  }

  const handleMenuItemClick =
    // eslint-disable-next-line jsdoc/require-jsdoc
    (callback: () => void) => (e: React.MouseEvent) => {
      e.preventDefault()
      callback()
      handleClose()
    }

  /**
   * Toggle the emenu
   * @param event - mouse event
   */
  const handleToggle = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  /**
   * Close the menu
   */
  const handleClose = () => {
    setAnchorEl(null)
  }

  const { downloadDriveFile } = useGapi()

  /**
   * Called when google drive files are selected
   * @param uploadItems - selected items
   */
  const onGoogleDriveDocumentAccepted = async (uploadItems: UploadItem[]) => {
    setIsUploading(true)
    try {
      await downloadFromGoogleDrive(uploadItems)
    } catch (ex) {
      alert("Failed to upload translation sheet from google drive")
    } finally {
      setIsUploading(false)
    }
  }

  /**
   * Download image from google drive
   * @param uploadItems - list of selections
   */
  const downloadFromGoogleDrive = async (
    uploadItems: UploadItem[]
  ): Promise<string | undefined> => {
    const fileObjects = getGoogleDriveFilesFromUploadItems(uploadItems)
    if (fileObjects.length > 0) {
      const uploadFile = fileObjects[0]
      console.log(fileObjects)

      let errorMessage = await downloadDriveUploadItem(
        uploadFile,
        downloadDriveFile
      )

      if (errorMessage != null) {
        // try a second time - google drive sometimes fails on the first try
        errorMessage = await downloadDriveUploadItem(
          uploadFile,
          downloadDriveFile
        )
      }

      if (errorMessage != null) {
        return errorMessage
      } else {
        if (uploadFile.file instanceof File) {
          const fontUrl = URL.createObjectURL(uploadFile.file)
          // For the text content of the font preview, grab whatever name has been given to the font file
          const fontName = uploadFile.file.name.split(".")[0]
          onPropertyChange(
            variant,
            { url: fontUrl, name: fontName },
            uploadFile.file
          )
        }
      }
    }
  }

  const { openDrivePicker, onAccessPermitted } = useGoogleDrivePicker({
    onAccepted: onGoogleDriveDocumentAccepted,
    setGoogleDriveAccessPromptDialogOpen,
    mimeTypeCategories: [MimeTypeCategory.FONTS],
  })

  /** Called on Allow access to Google Drive */
  const handleOkGoogleDriveAccessPromptDialog = () => {
    setGoogleDriveAccessPromptDialogOpen(false)
    onAccessPermitted()
  }

  /**
   * Handle file upload of font. Create a URL to display it to the user and
   * store the file object itself in the branding.changes object in the state.
   *
   * @param event - File input event
   */
  const onAdd = (event: any) => {
    if (event.target.files) {
      const file = event.target.files[0]
      const fontUrl = URL.createObjectURL(file)
      // For the text content of the font preview, grab whatever name has been given to the font file
      const fontName = file.name.split(".")[0]
      onPropertyChange(variant, { url: fontUrl, name: fontName }, file)
    }
  }

  const fontOptions = recentFonts[variant].concat(googleFonts)

  return (
    <Stack direction="row" gap={2} alignItems="center">
      <Autocomplete
        fullWidth
        sx={{
          "& input": {
            font: `1rem "${selectedFont.name}"`,
          },
        }}
        ListboxComponent={ListBoxComponent}
        onChange={(event: any, newValue: IGoogleFont) => {
          if (newValue == null) {
            onPropertyChange(variant, { url: null, name: null })
          } else {
            updateRecentFonts(newValue, variant)
            onPropertyChange(variant, newValue)
          }
        }}
        value={selectedFont}
        renderInput={(params) => (
          <TextField
            sx={(theme: Theme) => ({ color: "red" })}
            {...params}
            label={title}
          />
        )}
        getOptionLabel={(option) => {
          if (!option.name) {
            return ""
          }
          return option.name
        }}
        isOptionEqualToValue={(option, value) => option.name === value.name}
        renderOption={(props, option, state: any) =>
          [props, option, state.index] as ReactNode
        }
        options={fontOptions}
      />
      <ButtonGroup
        variant="outlined"
        ref={anchorRef}
        disabled={isUploading}
        aria-label="split button"
        sx={{
          boxShadow: "none",
        }}
      >
        <Button
          variant="outlined"
          onClick={handleButtonClick}
          startIcon={isUploading ? <CircularProgress size={24} /> : <></>}
        >
          <input
            hidden
            type="file"
            ref={fileInputRef}
            accept=".ttf,.otf,.woff2,.woff"
            onChange={onAdd}
          />
          Upload
        </Button>
        <Button
          data-cy="export-screen-export-course-button-menu"
          size="small"
          onClick={handleToggle}
          sx={{
            px: 0.5,
            minWidth: "auto",
          }}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem onClick={handleMenuItemClick(() => openDrivePicker())}>
          <GoogleDriveIcon sx={{ mr: 1 }} />
          From Google Drive (Beta)
        </MenuItem>
      </Menu>
      <GoogleDriveAccessDialog
        open={googleDriveAccessPromptDialogOpen}
        onClose={() => setGoogleDriveAccessPromptDialogOpen(false)}
        onOk={handleOkGoogleDriveAccessPromptDialog}
      />
    </Stack>
  )
}

export default FontSelectionControl

import { Button } from "@mui/material"
import axios from "axios"
import { useSnackbar } from "notistack"
import { useEffect, useRef } from "react"

const initialBuildVersion = process.env.REACT_APP_BUILD_VERSION ?? null
const url = window.location.origin

/**
 * Watch for changes in the build version of the application, and prompt for a refresh if a new version is available.
 */
const useRefreshBrowser = () => {
  const buildVersion = useRef(initialBuildVersion)
  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    /**
     * Send request to index.html to get the build version from metadata
     */
    const checkBuildVersion = () => {
      if (document.visibilityState === "visible") {
        axios.get(`${url}`).then((response) => {
          const parser = new DOMParser()

          // Parse the text
          const doc = parser.parseFromString(response.data, "text/html")

          // Find the meta tag with name "build-version"
          const buildVersionMetaTag = doc.querySelector(
            'meta[name="build-version"]'
          )

          if (buildVersionMetaTag) {
            const newBuildVersion = buildVersionMetaTag.getAttribute("content")

            if (newBuildVersion && newBuildVersion !== buildVersion.current) {
              console.log("Current version: " + buildVersion.current)
              console.log("Latest version: " + newBuildVersion)
              enqueueSnackbar(
                "A new version of the application is available.",
                {
                  preventDuplicate: true,
                  persist: true,
                  /**
                   * Display button to refresh the browser
                   */
                  action: () => {
                    return (
                      <Button
                        variant="text"
                        onClick={() => {
                          buildVersion.current = newBuildVersion
                          window.location.reload()
                        }}
                      >
                        Refresh
                      </Button>
                    )
                  },
                }
              )
            }
          }
        })
      }
    }

    checkBuildVersion()

    window.addEventListener("focus", checkBuildVersion)
    return () => {
      window.removeEventListener("focus", checkBuildVersion)
    }
  }, [enqueueSnackbar])
}

export default useRefreshBrowser

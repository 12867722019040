import waitForExportJob from "./waitForExportJob"
import * as api from "../../../api"
import { ExportLocation, GoogleWorkspaceType } from "../../export/ExportTypes"
import {
  exportAsyncStatusEnum,
  ExportTargetName,
} from "../../export/utils/contants"
import {
  GoogleDriveUploadResult,
  uploadUrlToGoogleDrive,
} from "../../export/api/uploadToGoogleDrive"
import { makeMicroLearningExportFilename } from "../../../utilities/exportUtils"
import getMicroLearningExportPreview from "./getMicroLearningExportPreview"
import { MicroLearningExportConfig } from "./types"

// Add new interface for the return type
interface ProcessedExportFile {
  exportUrl: string
  fileName: string
}

/**
 * Process a single export file through preview and export steps
 * @param fileData - the file to save
 * @param exportParams - params to pass to export service
 * @param saveTo - destination id google drive
 */
const processExportFile = async (
  fileData: MicroLearningExportConfig,
  exportParams: any,
  saveTo?: GoogleWorkspaceType
): Promise<ProcessedExportFile> => {
  // First generate and wait for preview
  const previewResponse = await getMicroLearningExportPreview(fileData)

  const exportParamsCopy = JSON.parse(JSON.stringify(exportParams))
  exportParamsCopy["course"]["html"] = previewResponse.html

  if (fileData.optionId) {
    const matchingOption = fileData.optionList?.find(
      (option) => option.key === fileData.optionId
    )

    exportParamsCopy["course"]["title"] =
      matchingOption?.label || fileData.optionId
  }

  // After preview, use standard export service
  const exportResponse = await api.startExport(exportParamsCopy)
  const export_job_id = exportResponse.data.job_id

  const exportResult = await waitForExportJob(export_job_id, {
    completeStatus: exportAsyncStatusEnum.COMPLETE,
    failedStatus: exportAsyncStatusEnum.FAILED_NO_RETRY,
  })

  if (exportResult.export_url != null) {
    const baseFilename = extractFilenameFromUrl(exportResult.export_url)
    const fileName = makeMicroLearningExportFilename(
      baseFilename || "Untitled",
      saveTo
    )

    return {
      exportUrl: exportResult.export_url,
      fileName,
    }
  } else {
    throw new Error("Unable to create export file")
  }
}

/**
 * Submit the async jobs to build the files for microlearning and return URLs
 * for download. For each file, preview must complete before export starts,
 * but multiple files can be processed in parallel.
 * @param data - MicroLearningExportConfig
 * @param target - target - check for gdrive
 * @param location Place in GDrive, if exporting there
 */
const exportMicroLearningContent = async (
  data: MicroLearningExportConfig,
  target: ExportTargetName,
  location?: ExportLocation
): Promise<GoogleDriveUploadResult | null> => {
  // Determine if we're dealing with multiple files or just one
  const exportFiles =
    data.organizeBy === "FULL_DOCUMENT"
      ? [data]
      : data.checkedOptions.map((option: any) => ({
          ...data,
          optionId: option.key ? option.key : option,
        }))

  // Get export parameters from the microlearning export endpoint
  const exportParams = await api.createMicroLearningExportParams(
    data.courseId,
    data.format || "docx"
  )
  const courseTitle = exportParams["course"]["title"]

  // Process export files
  const results: ProcessedExportFile[] = []
  for (const fileData of exportFiles) {
    const result = await processExportFile(fileData, exportParams)
    results.push(result)
  }

  // If we have multiple files, create a zip package
  let finalExportUrl: string
  let finalFileName: string

  if (results.length > 1) {
    // Create zip package containing all files
    const zipData = await api.createMicroLearningZip(
      data.courseId,
      results.map((r) => ({ url: r.exportUrl, filename: r.fileName }))
    )
    finalExportUrl = zipData
    finalFileName = `${courseTitle}-${data.organizeBy.toLowerCase()}.zip`
  } else {
    finalExportUrl = results[0].exportUrl
    finalFileName = results[0].fileName
  }

  // Handle the results based on export target
  if (target === "google-drive" && location != null) {
    const fileName = makeMicroLearningExportFilename(
      finalFileName,
      location?.saveTo
    )
    return uploadUrlToGoogleDrive(
      finalExportUrl,
      location.folderId,
      location.folderName,
      fileName,
      location.saveTo
    )
  } else {
    const fileName = makeMicroLearningExportFilename(finalFileName)
    await api.downloadFile(finalExportUrl, fileName)
  }

  return null
}

export default exportMicroLearningContent

/**
 * Extracts the filename from a URL, removing any query parameters
 * @param url The URL to parse
 * @returns The filename without query parameters
 */
const extractFilenameFromUrl = (url: string): string => {
  try {
    const urlObj = new URL(url)
    const pathname = urlObj.pathname
    return pathname.split("/").pop() || "Untitled"
  } catch {
    // Fallback in case URL parsing fails
    return url.split("?")[0].split("/").pop() || "Untitled"
  }
}
